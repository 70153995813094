<template>
  <div class="access-denied">
    <img :draggable="false" src="../assets/images/access_denied.png" />
  </div>
</template>

<script>
export default {
  name: 'AccessDenied',
};
</script>

<style lang="scss">
.access-denied {
  display: flex;
  justify-content: center;
}
</style>
