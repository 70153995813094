<template>
  <va-card>
    <div class="row align--center" v-if="canAddManga">
      <div class="flex xs4">
        <va-button @click="navigateToAddManga()">Add Manga</va-button>
      </div>
    </div>
    <div v-if="canListMangas">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <!-- <va-input
            v-model="term"
            :placeholder="$t('tables.searchByName')"
            @keydown="searchManga"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input> -->
          <ajax-search-box :placeholder="$t('tables.searchByName')" :disable="backgroundSearchLoading"
            :api-call-function="searchMangaByKeyword" v-on:select="mangaSelectedFromSearch" />
        </div>

        <div class="flex xs12 md3 offset--md3">
          <va-select v-model="perPage" :label="$t('tables.perPage')" :options="perPageOptions" noClear />
        </div>
      </div>
      <loader v-if="isLoading" />
      <data-table :fields="fields" :data="mangas" :per-page="pagination.limit" :total-pages="pagination.pages"
        :currentPage="pagination.currentPage" :api-mode="true" :loading="apiLoading" @page-selected="loadMangas" v-else>
        <template slot="cover" slot-scope="props">
          <img class="thumb-pic" :src="props.rowData.id | mangaThumbFilter" />
        </template>
        <template slot="status" slot-scope="props">
          <manga-status :status="props.rowData.status" :mangaId="props.rowData.id" @updateManga="updateMangaArray"
            v-if="canEditManga" />
        </template>
        <template slot="disabled" slot-scope="props">
          <manga-disabled :disabled="props.rowData.disabled" :mangaId="props.rowData.id" @updateManga="updateMangaArray"
            v-if="canEditManga" />
        </template>
        <template slot="actions" slot-scope="props">
          <a @click="navigateToViewManga(props.rowData.id)" v-if="canReadManga">
            <va-icon name="fa fa-eye" :size="21" class="action-icon pointer" />
          </a>
          <a @click="navigateToEditManga(props.rowData.id)" v-if="canEditManga">
            <va-icon name="fa fa-pencil-square-o" :size="21" class="action-icon pointer" />
          </a>
          <a @click="
            setDeleteManga(false, props.rowData.id, props.rowData.title)
            " v-if="canDeleteManga">
            <va-icon name="fa fa-trash-o" :size="21" class="action-icon pointer" />
          </a>
          <a @click="setDeleteManga(true, props.rowData.id, props.rowData.title)" v-if="canDeleteManga">
            <va-icon name="fa fa-trash-o" :size="21" class="action-icon pointer force-action-item" />
          </a>
        </template>
      </data-table>
    </div>
    <access-denied v-else />
    <va-modal v-model="showDeleteModal" size="small" title="Delete Manga" :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')" @ok="deleteMangaById(false)" @cancel="resetModal">
      Are you sure you want to delete
      <b>{{ selectedDeleteMangaLabel }}</b> manga?
    </va-modal>
    <va-modal v-model="showForceDeleteModal" size="small" title="Delete Manga" :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')" @ok="deleteMangaById(true)" @cancel="resetModal">
      Are you sure you want to <b>FORCE</b> delete
      <b>{{ selectedDeleteMangaLabel }}</b> manga?
    </va-modal>
  </va-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import {
  fetchMangas,
  forceDeleteManga,
  deleteManga,
} from '../../apollo/api/mangas';
import { isAllowed } from '../../mixins/utils';
import { mangaThumbFilter } from '../../mixins/filters';
import { MANGA_TABLE_FIELDS } from '../../constants/tableFields';
import DataTable from '../../components/DataTable';
import AccessDenied from '../../components/AccessDenied.vue';
import MangaDisabled from './MangaDisabled';
import MangaStatus from './MangaStatus';
import Loader from '../../components/Loader';
import AjaxSearchBox from '../../components/AjaxSearch';

export default {
  components: {
    AccessDenied,
    DataTable,
    MangaDisabled,
    MangaStatus,
    Loader,
    AjaxSearchBox,
  },
  data() {
    return {
      term: '',
      apiLoading: false,
      backgroundSearchLoading: false,
      showDeleteModal: false,
      showForceDeleteModal: false,
      selectedDeleteMangaId: null,
      selectedDeleteMangaLabel: '',
      mangas: [],
      perPageOptions: ['10', '20', '30', '40'],
      perPage: '20',
      pagination: {
        limit: 20,
        currentPage: 1,
        pages: 0,
        total: 0,
      },
    };
  },
  filters: {
    mangaThumbFilter
  },
  computed: {
    ...mapGetters(['isLoading', 'ACCESS_CODES', 'RESOURCE_CODES']),
    fields() {
      return MANGA_TABLE_FIELDS;
    },
    canListMangas() {
      return isAllowed([this.RESOURCE_CODES.MANGA([this.ACCESS_CODES.LIST])]);
    },
    canReadManga() {
      return isAllowed([
        this.RESOURCE_CODES.MANGA([
          this.ACCESS_CODES.LIST,
          this.ACCESS_CODES.READ,
        ]),
      ]);
    },
    canAddManga() {
      return isAllowed([this.RESOURCE_CODES.MANGA([this.ACCESS_CODES.CREATE])]);
    },
    canEditManga() {
      return isAllowed([
        this.RESOURCE_CODES.MANGA([
          this.ACCESS_CODES.LIST,
          this.ACCESS_CODES.READ,
          this.ACCESS_CODES.EDIT,
        ]),
      ]);
    },
    canDeleteManga() {
      return isAllowed([
        this.RESOURCE_CODES.MANGA([
          this.ACCESS_CODES.LIST,
          this.ACCESS_CODES.DELETE,
        ]),
      ]);
    },
  },
  watch: {
    perPage: function (newVal) {
      this.pagination.limit = parseInt(newVal);
      this.loadMangas();
    },
    term: function (newVal, oldVal) {
      if (oldVal.length > 0 && newVal.length === 0) {
        this.loadMangas();
      }
    },
  },
  async mounted() {
    await this.loadMangas(1);
  },
  methods: {
    ...mapMutations(['setLoading', 'setBackgroundLoading']),
    async searchMangaByKeyword(mangaQuery) {
      if (this.backgroundSearchLoading) return [];
      this.backgroundSearchLoading = true;

      try {
        const { mangasList } = await fetchMangas(mangaQuery, 4, 1);
        this.backgroundSearchLoading = false;
        return mangasList.mangas;
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.backgroundSearchLoading = false;
    },
    mangaSelectedFromSearch(manga) {
      this.$router.push({
        name: 'view-manga',
        params: { id: manga.id },
      });
    },
    async loadMangas(page = 1) {
      if (this.isLoading) return;
      this.setLoading(true);

      try {
        const { mangasList } = await fetchMangas(
          this.term,
          this.pagination.limit,
          page
        );
        this.mangas = mangasList.mangas;
        this.pagination = {
          ...this.pagination,
          currentPage: mangasList.currentPage,
          pages: mangasList.pages,
          total: mangasList.total,
        };
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setLoading(false);
    },
    async searchManga(e) {
      if (e.key === 'Enter') {
        await this.loadMangas();
      }
    },
    updateMangaArray(manga) {
      const newMangas = this.mangas.map(m => {
        if (m.id === manga.id) {
          return { ...m, ...manga };
        }
        return m;
      });
      this.mangas = newMangas;
    },
    resetModal() {
      this.selectedDeleteMangaId = null;
      this.selectedDeleteMangaLabel = '';
      this.showDeleteModal = false;
      this.showForceDeleteModal = false;
    },
    setDeleteManga(forced, mangaId, label) {
      this.selectedDeleteMangaId = mangaId;
      this.selectedDeleteMangaLabel = label;
      if (forced) this.showForceDeleteModal = true;
      else this.showDeleteModal = true;
    },
    async deleteMangaById(forceDeleteFlag = false) {
      this.setBackgroundLoading(true);
      try {
        if (forceDeleteFlag) {
          await forceDeleteManga(this.selectedDeleteMangaId);
        } else {
          await deleteManga(this.selectedDeleteMangaId);
        }
        this.showToast('Manga deleted successfully', {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
        window.location.reload();
      } catch (e) {
        console.log(e);
        this.showToast(e, {
          position: 'top-right',
          duration: 2000,
          fullWidth: false,
        });
      }
      this.setBackgroundLoading(false);
    },
    navigateToAddManga() {
      this.$router.push({ name: 'add-manga' });
    },
    navigateToEditManga(mangaId) {
      this.$router.push({ name: 'edit-manga', params: { id: mangaId } });
    },
    navigateToViewManga(mangaId) {
      this.$router.push({ name: 'view-manga', params: { id: mangaId } });
    },
  },
};
</script>
